/* import __COLOCATED_TEMPLATE__ from './sub-migration-choice-summary.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import templateOnlyComponent from '@ember/component/template-only';
import type Price from 'embercom/models/price';

interface Args {
  translationKey: string;
  featuresNewTranslation: string;
  featuresLostTranslation: string;
  summaryCalculationTranslation: string;
  estimatedPriceDifference: number;
  featuresLostCount: number;
  onSelectionChange: $TSFixMe;
  estimatedBasePrice: number;
  translationsByKeys: { [key: string]: string };
  postMigrationPlanEstimate: Price;
  usageDiscount: number;
}

interface Signature {
  Args: Args;
}

const BillingMigrationChoiceSummary = templateOnlyComponent<Signature>();
export default BillingMigrationChoiceSummary;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::SubMigrationChoiceSummary': typeof BillingMigrationChoiceSummary;
  }
}
